import React, { Fragment } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Login from './pages/Login'
import Rating from './pages/Rating'
import ThankYou from './pages/ThankYou'
import Page404 from './pages/Page404'
import { ToastContainer } from 'react-toastify'

function Routes() {

  return (<Fragment>
    <BrowserRouter>
      <Switch>
        <Route exact path='/' render={() => <Redirect to='/login' />} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/rating' component={Rating} />
        <Route exact path='/thankyou' component={ThankYou} />
        <Route name='Page 404' component={Page404} />
      </Switch>
    </BrowserRouter>
    <ToastContainer />
  </Fragment>
  )
}

export default Routes;

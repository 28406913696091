import React, { useEffect } from 'react'
import Logo from '../assets/Logo.svg'

function ThankYou(props) {

  useEffect(() => {
    // Update the document title using the browser API
    document.title = 'Thank you- Tech Talks by Cappital'
  })

  return (
    <div className='page'>
      <div className='d-flex flex-column justify-content-center align-items-center h-100'>
        <h1>THANK YOU!</h1>
        <div className='text-muted'>THANK YOU FOR ATTENDING!</div>
      </div>
      <div className='d-flex justify-content-center align-items-center w-100' style={{ position: 'absolute', bottom: 40 }}>
        <img src={Logo} alt='Cappital Logo' />
      </div>
    </div>
  )
}

export default ThankYou

import React, { useState, useEffect } from 'react'
import { Button, Input, Row, Col } from 'reactstrap'
import { trimStart } from 'lodash'
import { EMAIL } from '../common/validations'
import Logo from '../assets/Logo.svg'

function Login(props) {

  const [name, setName] = useState(undefined)
  const [email, setEmail] = useState(undefined)

  function onCheckInClick() {
    getLocalStorageItem.setItem('email', email)
    getLocalStorageItem.setItem('name', name).then(() => {
      props.history.push('/rating')
    })
  }

  const getLocalStorageItem = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        return localStorage.setItem(key, value);
      })
    }
  }

  useEffect(() => {
    // Update the document title using the browser API
    document.title = 'Login- Tech Talks by Cappital'
  })

  return (
    <div className="page">
      <div className='header'>
        <img src={Logo} alt='cappital logo' className='header-logo' />
      </div>
      <div className='p-4 login-inputs-container'>
        <Row className='w-100'>
          <Col md={{ size: 6, offset: 3 }}>
            <div>
              <Input className='login-input'
                name="name"
                placeholder="Full Name *"
                value={name}
                invalid={name ? !trimStart(name) : false}
                onChange={(e) => setName(e.target.value)}
              />
              <Input className='login-input mt-3'
                type="email"
                name="email"
                placeholder="Email *"
                value={email}
                invalid={email ? EMAIL({ value: email }) : false}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className='p-4 w-100' style={{ position: 'absolute', bottom: 20 }}>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Button size='lg' color="primary"
              disabled={EMAIL({ value: email }) || (!trimStart(name))}
              className='w-100'
              onClick={() => onCheckInClick()}>Leave Feedback</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Login;

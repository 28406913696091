import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'

export default function Page404() {

  useEffect(() => {
    // Update the document title using the browser API
    document.title = 'Page not found- Tech Talks by Cappital'
  })

  return (
    <div className="page flex-row justify-content-center align-items-center h-100">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">404</h1>
              <h4 className="pt-3">Oops! You are lost.</h4>
              <p className="text-muted float-left">The page you are looking for was not found.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { Card, Input, Row, Col, Button } from 'reactstrap'
import { clone } from 'lodash'
import { toast } from 'react-toastify'
import ConfirmationModal from './ConfirmationModal'
import Logo from '../assets/Logo.svg'
import Star from '../assets/Star.svg'
import UnStar from '../assets/UnStar.svg'
import Checkmark from '../assets/Checkmark.svg'
import { PRIMARY } from '../common/constants'
import fireStoreDb from '../FirebaseInit'

function Rating(props) {

  const [ratings, setRatings] = useState([false, false, false, false, false])
  const [reason, setReason] = useState('')
  const [liked, setLiked] = useState(null)
  const [futureTechnologies, setFutureTechnologies] = useState([])
  const [otherTechnology, setOtherTechnology] = useState('')
  const [intrested, setIntrested] = useState(null)
  const [bestTime, setBestTime] = useState('')
  const [loading, setLoading] = useState(false)
  const [modalVisibility, setModalVisibility] = useState(false)

  const futureTechnologiesArray = ['Internet of Things (IoT)', 'Artificial Intelligence', 'Machine Learning', 'Blockchain', 'Virtual and Augmented Reality']

  const bestTimeToContactArray = ['Morning', 'Mid Day', 'Afternoon', 'Evening']

  useEffect(() => {
    // Update the document title using the browser API
    document.title = 'Rating- Tech Talks by Cappital'
    getLocalStorageItem.getItem('name').then((name) => {
      if (!name) {
        props.history.push('/')
      }
    })
  })

  function setSelectedTechnologies(technology) {
    const technologies = clone(futureTechnologies)
    if (technologies.includes(technology)) {
      const index = technologies.indexOf(technology)
      technologies.splice(index, 1)
      if (technology === 'other') {
        setOtherTechnology('')
      }
    } else {
      technologies.push(technology)
    }
    setFutureTechnologies(technologies)
  }

  const getLocalStorageItem = {
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      })
    },
    removeItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.removeItem(key);
      })
    }
  }

  function submit() {
    const { history } = props
    setLoading(true)
    setModalVisibility(false)
    let selectedTechnologies = []
    if (futureTechnologies.includes('other')) {
      const technologies = clone(futureTechnologies)
      const index = technologies.indexOf('other')
      technologies.splice(index, 1)
      technologies.push(otherTechnology)
      selectedTechnologies = technologies
    } else {
      selectedTechnologies = futureTechnologies
    }
    fireStoreDb.collection('feedbacks').add({
      email: localStorage.getItem('email'),
      name: localStorage.getItem('name'),
      event: 'Event1',
      'How valuable was the event?': ratings.filter(value => value).length,
      'Are you interested in attending events that are a part of this series in the future?': liked,
      'reason': reason,
      'What future technology topics do you want to hear about?': selectedTechnologies,
      'Are you interested in a free brainstorming session?': intrested,
      'What is the best time of day for our team to contact you?': bestTime
    }).then(function (docRef) {
      setLoading(false)
      history.push('/thankyou')
      console.log('Document written with ID: ', docRef.id)
      getLocalStorageItem.removeItem('name')
      getLocalStorageItem.removeItem('email')
    }).catch(function (error = {}) {
      console.error('Error adding document: ', error)
      setLoading(false)
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  const onStarClick = (selectedIndex) => {
    const updatedRatings = ratings.map((star, index) => {
      if (index < (selectedIndex + 1)) {
        return true
      }
      return false
    })
    setRatings(updatedRatings)
  }

  const likeSvg = (isSelected) => <svg width="32px" height="31px" viewBox="0 0 32 31" version="1.1">
    <title>Like</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <rect id="path-1" x="0" y="0" width="32" height="32"></rect>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Like" transform="translate(0.000000, -1.000000)">
        <path d="M12.3558889,30.9652872 C11.3494176,30.9652872 10.5258686,30.1420038 10.5258686,29.1355325 L10.5258686,14.816082 C10.5258686,12.5339329 17.7556831,8.3861879 18.1417051,6.1313842 C18.3875485,4.69243023 18.0609962,1 19.0549895,1 C20.7453622,1 22.9083065,1.64752929 22.9083065,5.39358842 C22.9083065,8.69787276 20.7453622,12.9863273 20.7453622,12.9863273 L29.5025387,12.9863273 C30.882023,12.9863273 32,14.1043043 32,15.4829921 C32,16.6686689 31.1729997,17.6586799 30.0659078,17.9138154 C30.6361796,18.3715196 31.0028208,19.0740052 31.0028208,19.8622441 C31.0028208,21.0481864 30.176617,22.0379318 29.0687286,22.2938639 C29.6397969,22.7513026 30.0064381,23.4532572 30.0064381,24.2422925 C30.0064381,25.4274384 29.1794378,26.4182458 28.0715495,26.6739123 C28.6426177,27.131351 29.009259,27.8333057 29.009259,28.6220755 C29.009259,30.0010288 27.8915475,31.1198022 26.5120632,31.1198022 L12.3558889,30.9652872 Z M8.68204294,29.1355325 C8.68204294,30.1420038 7.85849401,30.9652872 6.85228819,30.9652872 L1.82948926,30.9652872 C0.823283443,30.9652872 0,30.1420038 0,29.1355325 L0,14.816082 C0,13.8098762 0.823283443,12.9863273 1.82948926,12.9863273 L6.85228819,12.9863273 C7.85849401,12.9863273 8.68204294,13.8098762 8.68204294,14.816082 L8.68204294,29.1355325 Z" fill={isSelected ? PRIMARY : "#f2f2f2"}></path>
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <g id="Rectangle"></g>
      </g>
    </g>
  </svg>

  const dislikeSvg = (isSelected) => <svg width="32px" height="31px" viewBox="0 0 32 31" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Dislike</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <rect id="path-1" x="0" y="0" width="32" height="32"></rect>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dislike" transform="translate(0.000000, -1.000000)">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <g id="Rectangle"></g>
        <path d="M12.3558889,30.9652872 C11.3494176,30.9652872 10.5258686,30.1420038 10.5258686,29.1355325 L10.5258686,14.816082 C10.5258686,12.5339329 17.7556831,8.3861879 18.1417051,6.1313842 C18.3875485,4.69243023 18.0609962,1 19.0549895,1 C20.7453622,1 22.9083065,1.64752929 22.9083065,5.39358842 C22.9083065,8.69787276 20.7453622,12.9863273 20.7453622,12.9863273 L29.5025387,12.9863273 C30.882023,12.9863273 32,14.1043043 32,15.4829921 C32,16.6686689 31.1729997,17.6586799 30.0659078,17.9138154 C30.6361796,18.3715196 31.0028208,19.0740052 31.0028208,19.8622441 C31.0028208,21.0481864 30.176617,22.0379318 29.0687286,22.2938639 C29.6397969,22.7513026 30.0064381,23.4532572 30.0064381,24.2422925 C30.0064381,25.4274384 29.1794378,26.4182458 28.0715495,26.6739123 C28.6426177,27.131351 29.009259,27.8333057 29.009259,28.6220755 C29.009259,30.0010288 27.8915475,31.1198022 26.5120632,31.1198022 L12.3558889,30.9652872 Z M8.68204294,29.1355325 C8.68204294,30.1420038 7.85849401,30.9652872 6.85228819,30.9652872 L1.82948926,30.9652872 C0.823283443,30.9652872 0,30.1420038 0,29.1355325 L0,14.816082 C0,13.8098762 0.823283443,12.9863273 1.82948926,12.9863273 L6.85228819,12.9863273 C7.85849401,12.9863273 8.68204294,13.8098762 8.68204294,14.816082 L8.68204294,29.1355325 Z" fill={isSelected ? PRIMARY : "#f2f2f2"} mask="url(#mask-2)" transform="translate(16.000000, 16.059901) rotate(-180.000000) translate(-16.000000, -16.059901) "></path>
      </g>
    </g>
  </svg>

  const radioSvg = (isSelected) => <svg width="26px" height="26px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Checkbox</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M16,32 C24.836556,32 32,24.836556 32,16 C32,7.163444 24.836556,0 16,0 C7.163444,0 0,7.163444 0,16 C0,24.836556 7.163444,32 16,32 Z M16,28.8 C8.94954257,28.8 3.22415094,23.0746084 3.22415094,16 C3.22415094,8.92539163 8.94954257,3.2 16,3.2 C23.0504574,3.2 28.7758491,8.92539163 28.7758491,16 C28.7758491,23.0746084 23.0504574,28.8 16,28.8 Z" id="Checkbox" fill={isSelected ? PRIMARY : "#f2f2f2"} fillRule="nonzero"></path>
    </g>
  </svg>

  return (
    <div className="page">
      <div className='header'>
        <img src={Logo} alt='cappital logo' className='header-logo' />
      </div>
      <div className='px-4 pt-3' style={{ overflowY: 'auto' }}>
        <ConfirmationModal
          closeModal={() => setModalVisibility(false)}
          isVisible={modalVisibility}
          submit={() => submit()}
        />
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Card className='box'>
              <h5>How valuable was the event?</h5>
              <div className='mt-3'>
                {
                  ratings.map((star, index) => <span key={index} className='mr-2' onClick={() => onStarClick(index)}>
                    <img src={star ? Star : UnStar} alt='Star' />
                  </span>)
                }
              </div>
            </Card>
            <Card className='box mt-3'>
              <h5>Are you interested in attending events that are a part of this series in the future?</h5>
              <div className='mt-3'>
                <span className='mr-3' onClick={() => setLiked(true)}>
                  {likeSvg(liked)}
                </span>
                <span className='mr-3' onClick={() => setLiked(false)}>
                  {dislikeSvg(liked === false)}
                </span>
              </div>
              <div className='mt-3'>
                <Input className='regular-input' name="reason" placeholder="Reason"
                  value={reason} onChange={(e) => setReason(e.target.value)} />
              </div>
            </Card>
            <Card className='box mt-3'>
              <h5>What future technology topics do you want to hear about?</h5>
              {
                futureTechnologiesArray.map((technology, index) => <div key={index} className={`d-flex flex-row ${index > 0 ? 'mt-2' : ''}`}
                  onClick={() => setSelectedTechnologies(technology)}>
                  {(futureTechnologies.includes(technology)) && <img src={Checkmark} style={{ position: 'absolute', left: 24 }} alt='' />}
                  <div className='mr-3'>{radioSvg(futureTechnologies.includes(technology))}</div>
                  <div style={{ color: (futureTechnologies.includes(technology)) ? PRIMARY : 'inherit' }}>{technology}</div>
                </div>)
              }
              <div className='d-flex flex-row mt-2'>
                {(futureTechnologies.includes('other')) && <img src={Checkmark} onClick={() => setSelectedTechnologies('other')}
                  style={{ position: 'absolute', left: 24 }} alt='' />}
                <div className='mr-3' onClick={() => setSelectedTechnologies('other')}>{radioSvg(futureTechnologies.includes('other'))}</div>
                <Input className='regular-input' name="technology" placeholder="Other" disabled={!futureTechnologies.includes('other')}
                  value={otherTechnology} onChange={(e) => setOtherTechnology(e.target.value)} />
              </div>
            </Card>
            <Card className='box mt-3'>
              <h5>Are you interested in a free brainstorming session?</h5>
              <div>
                <span className='mr-3' onClick={() => setIntrested(true)}>
                  {likeSvg(intrested)}
                </span>
                <span className='mr-3' onClick={() => setIntrested(false)}>
                  {dislikeSvg(intrested === false)}
                </span>
              </div>
              <h5 className='mt-3'>What is the best time of day for our team to contact you?</h5>
              <div className='mt-3'>
                {
                  bestTimeToContactArray.map((time, index) => <div key={index} className={`d-flex flex-row ${index > 0 ? 'mt-2' : ''}`}
                    onClick={() => setBestTime(time)}>
                    {(time === bestTime) && <img src={Checkmark} style={{ position: 'absolute', left: 24 }} alt='' />}
                    <div className='mr-3'>{radioSvg(time === bestTime)}</div>
                    <div style={{ color: (time === bestTime) ? PRIMARY : 'inherit' }}>{time}</div>
                  </div>)
                }
              </div>
            </Card>
            <div className='my-4'>
              <Button size='lg' color="primary" disabled={loading} className='w-100' onClick={() => setModalVisibility(true)}>
                <i className={loading ? 'fa fa-spinner fa-spin mr-2' : ''} />Submit
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Rating;

import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'

function ConfirmationModal(props) {
  const { isVisible, closeModal, submit } = props
  return (
    <Modal isOpen={isVisible} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Are you sure?</ModalHeader>
      <ModalBody>Are you sure that you want to submit this form?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={submit}>Submit</Button>{' '}
        <Button color="secondary" onClick={closeModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationModal

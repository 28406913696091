import { trimStart } from 'lodash'
import { REGEX } from './constants'

export const REQUIRED = ({ value, required = true }) => {
  if (required && !trimStart(value)) {
    return true
  }
  return false
}

export const EMAIL = ({ value, required = true }) => {
  const requiredError = REQUIRED({ value, required })
  if (!requiredError) {
    if (trimStart(value) && !REGEX.EMAIL.test(trimStart(value))) {
      return true
    }
    return false
  }
  return true
}
